import { Col, Form, InputGroup } from "react-bootstrap";

export function PelletTestCol(props) {
  const name = props.name ?? "";
  const unit = props.unit ?? "";
  const title = props.title ?? "";
  const defaultValue = props.defaultValue ?? "";
  const decimalPlace = isNaN(defaultValue)
    ? -1
    : defaultValue.toString().indexOf("."); //Find where the DP is in the value.
  const setToDP = decimalPlace > 1 ? 2 : 4; //For numbers >= 10, round to 2 dp, else round to 4 dp. This is so fines doesn't appear ridiculous.
  console.log(defaultValue.toString());

  return (
    <Col key={props.myKey} xs={12} lg={"6"} xl={"4"} className="mb-2">
      <InputGroup>
        <InputGroup.Text style={{ textTransform: "capitalize" }}>
          {title}
        </InputGroup.Text>
        <Form.Control
          type={"number"}
          name={name}
          readOnly={props.readOnly}
          disabled={props.disabled}
          defaultValue={
            decimalPlace !== -1 ? defaultValue.toFixed(setToDP) : defaultValue
          }
          onChange={props.onChange}
          step={0.0001}
        />
        <InputGroup.Text>{unit}</InputGroup.Text>
      </InputGroup>
    </Col>
  );
}
