import { useState } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "react-bootstrap";
import { useLoaderData, useNavigate } from "react-router";
import { PelletTestViewRow } from "../c_components/pelletTestViewRow";
import _ from "lodash";
import {
  UpdatePelletTest,
  GetTestById,
  ArchivePelletTest,
} from "../c_helpers/apiCaller";

const units = [
  [
    "g",
    "g",
    <>
      g/m<sup>3</sup>
    </>,
  ],
  ["g", "g", "%"],
  ["Pellets", "mm", "mm"],
  ["g", "g", "%"],
  ["g", "%"],
  ["g", "%"],
];

function setDataDelegate(
  data,
  settingFunction,
  changedData,
  fieldsetName,
  defaultData
) {
  let newData = data;
  newData[fieldsetName][changedData.name] = parseFloat(changedData.value); //Value is always string since inputs provide strings
  settingFunction(newData);
}

export function PelletTestView() {
  // const loaderData =;
  const [defaultData, setDefaultData] = useState(useLoaderData());
  const [data, setData] = useState(structuredClone(useLoaderData()));
  const [editing, setIsEditing] = useState();
  const [isLoading, setIsLoading] = useState();
  const [showArchive, setShowArchive] = useState(false);
  const navigate = useNavigate();

  function onSubmit(data, defaultData) {
    let changesPayload = {
      test_id: defaultData.pelletTest.test_id,
    };
    //Add data points if there is a difference.
    if (!_.isEqual(data.bulkDensity, defaultData.bulkDensity))
      changesPayload.bulkTest = data.bulkDensity;
    if (!_.isEqual(data.fineTest, defaultData.fineTest))
      changesPayload.fineTest = data.fineTest;
    if (!_.isEqual(data.lengthTest, defaultData.lengthTest))
      changesPayload.lengthTest = data.lengthTest;
    if (!_.isEqual(data.moistureTest, defaultData.moistureTest))
      changesPayload.moistureTest = data.moistureTest;
    if (!_.isEqual(data.moistureTestAlt, defaultData.moistureTestAlt))
      changesPayload.moistureTestAlt = data.moistureTestAlt;
    if (!_.isEqual(data.mechanicalTest, defaultData.mechanicalTest))
      changesPayload.mechanicalTest = data.mechanicalTest;

    return changesPayload;
  }
  async function ArchiveTest() {
    setIsLoading(true);
    await ArchivePelletTest(defaultData.pelletTest.test_id);
    alert("Archived test.");
    navigate("/dash", { replace: true });
  }
  return (
    <>
      <Modal show={showArchive}>
        <ModalHeader>Archive Pellet Test</ModalHeader>
        <ModalBody>
          <h3 class="text-danger">
            Warning, you are about to archive this pellet test. Are you sure you
            want to do this?
          </h3>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "space-between" }}>
          <Button
            variant="primary"
            type="button"
            onClick={() => setShowArchive(false)}
            disabled={isLoading}
          >
            Go Back
          </Button>
          <Button
            variant="danger"
            type="button"
            onClick={ArchiveTest}
            disabled={isLoading}
          >
            Archive
          </Button>
        </ModalFooter>
      </Modal>
      <Form>
        <Row>
          <Col xs={"auto"}>
            <h3>
              Test ID {defaultData.pelletTest.test_id ?? ""} | Submitted by{" "}
              {defaultData.pelletTest.tester_id ?? ""} |{" "}
              {new Date(defaultData.pelletTest.created_at).toLocaleDateString()}{" "}
              {" - "}
              {new Date(defaultData.pelletTest.created_at)
                .toLocaleTimeString()
                .substring(0, 5)}
            </h3>
            <small>Sample ID: {defaultData.pelletTest.sample_id}</small>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                setIsEditing(!editing);
              }}
              type={editing ? "button" : "reset"}
              style={{ minWidth: 60 }}
              className="me-2"
              variant="primary"
            >
              {editing ? "Cancel" : "Edit"}
            </Button>
            <Button
              disabled={!editing}
              type="button"
              style={{ minWidth: 60 }}
              variant={editing ? "success" : "primary"}
              onClick={async () => {
                var payload = onSubmit(data, defaultData);
                var resp = await UpdatePelletTest(payload);
                if (resp === 200) {
                  alert("saved");
                  let testData = await GetTestById(
                    defaultData.pelletTest.test_id
                  );
                  debugger;
                  let test_response = await testData.json();
                  setDefaultData(test_response);
                  setData(test_response);
                } else alert("error when saving.");

                setIsEditing(false);
              }}
            >
              Save
            </Button>
          </Col>
        </Row>

        {Object.entries(defaultData).map((fieldset, index) => {
          if (fieldset[0] === "pelletTest") return null;
          //Data structure: ['testName',{...}]
          let name = fieldset[0];
          let title = fieldset[0];
          if (fieldset[0].includes("Alt") || fieldset[0].includes("alt"))
            title = "Moisture Test (Oven)";
          const localData = fieldset[1];

          return (
            <PelletTestViewRow
              units={units[index - 1]}
              name={name}
              myKey={name}
              data={localData}
              title={title}
              editing={editing}
              onChange={(e) => {
                setDataDelegate(
                  data,
                  setData,
                  {
                    name: e.target.name,
                    value: e.target.value,
                  },
                  name,
                  defaultData
                );
              }}
            />
          );
        })}
      </Form>
      {"externalTest" in defaultData ? (
        ""
      ) : (
        <Row>
          <Col
            style={{ justifyContent: "space-between", display: "flex" }}
            xs={12}
            dir="horizontal"
          >
            <Button disabled>Add External Test</Button>
            <Button
              type="button"
              variant="danger"
              onClick={() => setShowArchive(true)}
            >
              Archive
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
}
