import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Tooltip,
  OverlayTrigger,
  Modal,
  Stack,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router";
import { CreatePelletTest, FormatDate } from "../c_helpers/apiCaller";
import { isInteger } from "lodash";

function CreateTestDataPayload(e, id) {
  e.preventDefault();
  const tester_id = id;
  const myForm = e.target.elements;
  //Filter Data
  var testData = {
    PelletTest: {
      created_at: myForm["creation_date"].value,
      tester_id: tester_id,
      sample_id: myForm["sample_id"].value,
    },
  };

  if (myForm["enableDensity"].checked) {
    testData.BulkTest = {
      unladen_weight: parseFloat(myForm["density_prop_a"].value).toFixed(2),
      total_weight: myForm["density_prop_b"].value,
    };
  }
  if (myForm["enableFine"].checked) {
    testData.FineTest = {
      total_sample_weight: myForm["fine_prop_a"].value,
      post_sieving: myForm["fine_prop_b"].value,
    };
  }
  if (myForm["enableLength"].checked) {
    testData.LengthTest = {
      total_pellet_samples: myForm["length_prop_a"].value,
      total_length: myForm["length_prop_b"].value,
    };
  }
  if (myForm["enableMechanical"].checked) {
    testData.MechanicalTest = {
      net_weight: myForm["mechanical_prop_a"].value,
      output_weight: myForm["mechanical_prop_b"].value,
    };
  }
  if (myForm["enableMoisture"].checked) {
    testData.MoistureTest = {
      net_weight: myForm["moisture_prop_a"].value,
      moisture_content: myForm["moisture_prop_b"].value,
    };
  }
  if (myForm["enableMoistureAlt"].checked) {
    testData.MoistureTestAlt = {
      net_weight: myForm["moisture_alt_prop_a"].value,
      moisture_content: myForm["moisture_alt_prop_b"].value,
    };
  }
  return testData;
}
async function HandleNewTestForm(payload) {
  return await CreatePelletTest(payload);
}

export function NewPelletTest() {
  //Property Enablers
  const [testData, setTestData] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [enableDensity, setDensityEnabler] = useState(false);
  const [enableFine, setFineEnabler] = useState(false);
  const [enableLength, setLengthEnabler] = useState(false);
  const [enableMoisture, setMoistureEnabler] = useState(false);
  const [enableMoistureAlt, setMoistureAltEnabler] = useState(false);
  const [enableMechanical, setMechaEnabler] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  //Tester Name
  const tester_id = useMsal().accounts[0].name;
  //Navigation
  const navigate = useNavigate();
  return (
    <Form
      id="new_test_form"
      onSubmit={(e) => {
        const payload = CreateTestDataPayload(e, tester_id); //Create payload.
        setTestData(payload); //Set payload.
        setShowConfirmation(true); //Show popup.
      }}
      onReset={(e) => {
        setDensityEnabler(false);
        setFineEnabler(false);
        setLengthEnabler(false);
        setMechaEnabler(false);
        setMoistureEnabler(false);
        setMoistureAltEnabler(false);
      }}
    >
      <Row id="pellet-test" className="mb-2">
        <Col>
          <h2>Internal Test Form</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Stack gap={2}>
            <InputGroup>
              <InputGroup.Text>Test Date:</InputGroup.Text>
              <Form.Control
                name="creation_date"
                type="Date"
                required
                max={FormatDate(Date.now())}
                defaultValue={FormatDate(Date.now())}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Sample ID:</InputGroup.Text>
              <Form.Control
                name="sample_id"
                required
                maxLength={12}
                defaultValue={""}
                placeholder={"ABC123..."}
              />
            </InputGroup>
          </Stack>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Button variant="danger" type="reset" className="me-2">
            Reset
          </Button>
          <Button
            variant="success"
            type="submit"
            disabled={
              !(
                enableDensity ||
                enableFine ||
                enableLength ||
                enableMechanical ||
                enableMoisture ||
                enableMoistureAlt
              )
            }
          >
            Save
          </Button>
        </Col>
      </Row>
      <Row id="test-toggles" className="mb-4">
        <Col>
          <fieldset>
            <legend>Test Types</legend>
            <Form.Check
              inline
              name="enableDensity"
              checked={enableDensity}
              label="Bulk Density"
              value={enableDensity}
              onChange={() => setDensityEnabler(!enableDensity)}
            />
            <Form.Check
              inline
              name="enableFine"
              checked={enableFine}
              label="Fine"
              value={enableFine}
              onChange={() => setFineEnabler(!enableFine)}
            />
            <Form.Check
              inline
              name="enableLength"
              checked={enableLength}
              label="Length"
              value={enableLength}
              onChange={() => setLengthEnabler(!enableLength)}
            />
            <Form.Check
              inline
              name="enableMechanical"
              checked={enableMechanical}
              label="Mechanical"
              value={enableMechanical}
              onChange={() => setMechaEnabler(!enableMechanical)}
            />
            <Form.Check
              inline
              name="enableMoisture"
              checked={enableMoisture}
              label="Moisture"
              value={enableMoisture}
              onChange={() => setMoistureEnabler(!enableMoisture)}
            />
            <Form.Check
              inline
              name="enableMoistureAlt"
              checked={enableMoistureAlt}
              label="Moisture (Oven)"
              value={enableMoistureAlt}
              onChange={() => setMoistureAltEnabler(!enableMoistureAlt)}
            />
          </fieldset>
        </Col>
      </Row>
      <Row
        as={"fieldset"}
        id="density-test"
        className="mb-2"
        style={{ opacity: enableDensity ? "100%" : "65%" }}
        disabled={!enableDensity}
      >
        <h3>
          Bulk Density &ndash;{" "}
          <OverlayTrigger
            delay={{ hide: 100, show: 100 }}
            overlay={(props) => (
              <Tooltip {...props}>Recommended sample weight: 2500g</Tooltip>
            )}
            placement={"top"}
          >
            <Link style={{ position: "relative" }} to={""}>
              ?
            </Link>
          </OverlayTrigger>
        </h3>

        <Col xs={12} md={6}>
          <InputGroup>
            <InputGroup.Text>Unladen Weight</InputGroup.Text>
            <Form.Control
              name="density_prop_a"
              type="number"
              min="2000"
              max="3000"
              step="0.001"
              placeholder="2300-2400"
              required={enableDensity}
            />
            <InputGroup.Text>g</InputGroup.Text>
          </InputGroup>
          <Form.Text muted>
            Expected weight to be between 2300g and 2400g
          </Form.Text>
        </Col>

        <Col xs={12} md={6}>
          <InputGroup>
            <InputGroup.Text>Unladen Weight With Sample</InputGroup.Text>
            <Form.Control
              name="density_prop_b"
              type="number"
              step="0.001"
              min={4000}
              max={6000}
              placeholder="4800-4900"
              required={enableDensity}
            />
            <InputGroup.Text>g</InputGroup.Text>
          </InputGroup>
          <Form.Text muted>
            Expected weight to be ~2500g greater than Unladen Weight
          </Form.Text>
        </Col>
      </Row>
      <Row
        as={"fieldset"}
        id="fine-test"
        className="mb-2"
        style={{ opacity: enableFine ? "100%" : "65%" }}
        disabled={!enableFine}
      >
        <Col xs={12}>
          <h3>
            Fine Test &ndash;{" "}
            <OverlayTrigger
              delay={{ hide: 100, show: 100 }}
              overlay={(props) => (
                <Tooltip {...props}>Recommended test weight: 700g</Tooltip>
              )}
              placement={"top"}
            >
              <Link style={{ position: "relative" }} to={""}>
                ?
              </Link>
            </OverlayTrigger>
          </h3>
        </Col>

        <Col xs={12} md={6}>
          <InputGroup>
            <InputGroup.Text>Pre-Sieve Weight</InputGroup.Text>
            <Form.Control
              type="number"
              min="450"
              step="0.001"
              placeholder="500"
              name="fine_prop_a"
              required={enableFine}
            />
            <InputGroup.Text>g</InputGroup.Text>
          </InputGroup>
        </Col>

        <Col xs={12} md={6}>
          <InputGroup>
            <InputGroup.Text>Post-Sieve Weight</InputGroup.Text>
            <Form.Control
              type="number"
              min="0"
              step="0.001"
              placeholder="400"
              name="fine_prop_b"
              required={enableFine}
            />
            <InputGroup.Text>g</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row
        as={"fieldset"}
        id="length-test"
        className="mb-2"
        style={{ opacity: enableLength ? "100%" : "65%" }}
        disabled={!enableLength}
      >
        <h3>
          Length Test &ndash;{" "}
          <OverlayTrigger
            delay={{ hide: 100, show: 100 }}
            overlay={(props) => (
              <Tooltip {...props}>
                Tip: Take a handful, pick the three longest, the three shortest,
                and a random four, for a total of 10 samples.
              </Tooltip>
            )}
            placement={"top"}
          >
            <Link style={{ position: "relative" }} to={""}>
              ?
            </Link>
          </OverlayTrigger>
        </h3>

        <Col xs={12} md={6}>
          <InputGroup>
            <InputGroup.Text>Total Samples</InputGroup.Text>
            <Form.Control
              type="number"
              min="10"
              placeholder="10"
              step="1"
              name="length_prop_a"
              required={enableLength}
            />
            <InputGroup.Text>Pellets</InputGroup.Text>
          </InputGroup>
        </Col>
        <Col xs={12} md={6}>
          <InputGroup>
            <InputGroup.Text>Total Length</InputGroup.Text>
            <Form.Control
              type="number"
              min="0"
              step="0.001"
              name="length_prop_b"
              placeholder="400"
              required={enableLength}
            />
            <InputGroup.Text>mm</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row
        as={"fieldset"}
        id="mechanical-test"
        className="mb-2"
        style={{ opacity: enableMechanical ? "100%" : "65%" }}
        disabled={!enableMechanical}
      >
        <h3>
          Mechanical Durability &ndash;{" "}
          <OverlayTrigger
            delay={{ hide: 100, show: 100 }}
            overlay={(props) => (
              <Tooltip {...props}>Recommended test weight: 500g</Tooltip>
            )}
            placement={"top"}
          >
            <Link style={{ position: "relative" }} to={""}>
              ?
            </Link>
          </OverlayTrigger>
        </h3>

        <Col xs={12} md={6}>
          <InputGroup>
            <InputGroup.Text>Net Weight</InputGroup.Text>
            <Form.Control
              name="mechanical_prop_a"
              type="number"
              min="0"
              step="0.001"
              placeholder="500"
              required={enableMechanical}
            />
            <InputGroup.Text>g</InputGroup.Text>
          </InputGroup>
        </Col>

        <Col xs={12} md={6}>
          <InputGroup>
            <InputGroup.Text>Output Weight</InputGroup.Text>
            <Form.Control
              name="mechanical_prop_b"
              type="number"
              min="0"
              step="0.001"
              placeholder="500"
              required={enableMechanical}
            />
            <InputGroup.Text>g</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row
        as={"fieldset"}
        id="moisture-test"
        className="mb-2"
        style={{ opacity: enableMoisture ? "100%" : "65%" }}
        disabled={!enableMoisture}
      >
        <h3>
          Moisture Test &ndash;{" "}
          <OverlayTrigger
            delay={{ hide: 100, show: 100 }}
            overlay={(props) => (
              <Tooltip {...props}>Recommended test weight: 280g</Tooltip>
            )}
            placement={"top"}
          >
            <Link style={{ position: "relative" }} to={""}>
              ?
            </Link>
          </OverlayTrigger>
        </h3>

        <Col xs={12} md={6}>
          <InputGroup>
            <InputGroup.Text>Net Weight</InputGroup.Text>
            <Form.Control
              name="moisture_prop_a"
              type="number"
              min="0"
              step="0.001"
              max="350"
              placeholder="280"
              required={enableMoisture}
            />
            <InputGroup.Text>g</InputGroup.Text>
          </InputGroup>
        </Col>

        <Col xs={12} md={6}>
          <InputGroup>
            <InputGroup.Text>Moisture Content</InputGroup.Text>
            <Form.Control
              name="moisture_prop_b"
              type="number"
              min="0"
              step="0.001"
              placeholder="6-9"
              required={enableMoisture}
            />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row
        as={"fieldset"}
        id="moisture-alt-test"
        className="mb-2"
        style={{ opacity: enableMoistureAlt ? "100%" : "65%" }}
        disabled={!enableMoistureAlt}
      >
        <h3>
          Moisture Test (Oven) &ndash;{" "}
          <OverlayTrigger
            delay={{ hide: 100, show: 100 }}
            overlay={(props) => (
              <Tooltip {...props}>Recommended test weight: 20 - 25g</Tooltip>
            )}
            placement={"top"}
          >
            <Link style={{ position: "relative" }} to={""}>
              ?
            </Link>
          </OverlayTrigger>
        </h3>

        <Col xs={12} md={6}>
          <InputGroup>
            <InputGroup.Text>Net Weight</InputGroup.Text>
            <Form.Control
              name="moisture_alt_prop_a"
              type="number"
              min="5.000"
              max="30.000"
              step="0.001"
              placeholder="25"
              required={enableMoistureAlt}
            />
            <InputGroup.Text>g</InputGroup.Text>
          </InputGroup>
        </Col>

        <Col xs={12} md={6}>
          <InputGroup>
            <InputGroup.Text>Moisture Content</InputGroup.Text>
            <Form.Control
              name="moisture_alt_prop_b"
              type="number"
              min="0"
              step="0.01"
              placeholder="6-9"
              required={enableMoistureAlt}
            />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Modal
        show={showConfirmation}
        onHide={() => {
          setTestData({});
          setShowConfirmation(false);
        }}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Submission Details</strong>
            <br></br>
            <h5>The following tests are about to be submitted.</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ul>
            {Object.entries(testData).map((keyValuePair) => {
              if (keyValuePair[0] === "PelletTest") return <></>;
              else
                return (
                  <li key={keyValuePair[0]}>
                    <strong>
                      {keyValuePair[0]
                        .replace("Test", " Test")
                        .concat(
                          keyValuePair[0].includes("Alt") ? " (Oven)" : ""
                        )
                        .replace("Alt", "")}
                    </strong>
                  </li>
                );
            })}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={async () => {
              setIsSubmitting(true);
              let result = await HandleNewTestForm(testData);
              setIsSubmitting(false);
              if (isInteger(result)) navigate(`/dash/pellet-test/${result}`);
              else
                throw new Response("", {
                  status: 500,
                  statusText: "Unable to establish connection.",
                });
            }}
            disabled={
              (!showConfirmation && Object.keys(testData).length === 0) ||
              isSubmitting
            }
          >
            Confirm.
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}
