import { Row, Col } from "react-bootstrap";
import { useRouteError } from "react-router";

export function Error404Page(props) {
  const error = useRouteError();
  const customError = props.data;
  // console.log("Error start:");
  // console.error(error);
  // console.log("Error end:");
  return (
    <Row
      style={{ textAlign: "center", justifyContent: "center", color: "red" }}
    >
      <Col md={6}>
        <h3>Error {customError?.status ?? error.status}</h3>
        <p>{customError?.statusText ?? error.statusText}</p>
      </Col>
    </Row>
  );
}
