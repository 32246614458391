import { useState } from "react";
import {
  Row,
  Col,
  Table,
  InputGroup,
  FormControl,
  Button,
  Form,
} from "react-bootstrap";
import { useLoaderData, useSearchParams } from "react-router";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
  ReferenceArea,
} from "recharts";
import { FormatDate } from "../../c_helpers/apiCaller";
function formatDate(x) {
  return new Date(x).toLocaleDateString();
}
export function WeeklyReport() {
  const data = useLoaderData();
  const [params] = useSearchParams();
  const [fromDate, setFromDate] = useState(
    params.get("fromDate") ?? FormatDate(Date.now())
  );
  const [toDate, setToDate] = useState(
    params.get("toDate") ?? FormatDate(Date.now() + 7 * 24 * 3600 * 1000)
  );

  return (
    <>
      <Row>
        <Col>
          <Form>
            <input type={"hidden"} name="fromDate" value={fromDate} />
            <input type={"hidden"} name="toDate" value={toDate} />
            <InputGroup>
              <InputGroup.Text>Week Start:</InputGroup.Text>
              <FormControl
                type="date"
                value={fromDate}
                onChange={(e) => {
                  setFromDate(FormatDate(e.target.value));
                  let d =
                    new Date(e.target.value).getTime() + 7 * 24 * 3600 * 1000;
                  setToDate(FormatDate(d));
                }}
                max={FormatDate(Date.now())}
              />
              <Button type="submit">Go</Button>
            </InputGroup>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Bulk Density</th>
                <th>Fine Fraction</th>
                <th>Durability</th>
                <th>Moisture Content</th>
                <th>Moisture Content (Oven)</th>
              </tr>
            </thead>
            <tbody>
              {data
                .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
                .map((row) => {
                  if (row.date != null)
                    return (
                      <tr key={row.date}>
                        <td>{new Date(row.date).toLocaleDateString()}</td>
                        <td>
                          {row.avg_density
                            ? (Math.round(row.avg_density * 100) / 100).toFixed(
                                2
                              )
                            : "-"}
                        </td>
                        <td>
                          {row.avg_fine_fraction
                            ? (
                                Math.round(row.avg_fine_fraction * 10000) /
                                10000
                              ).toFixed(4)
                            : "-"}
                          &#37;
                        </td>
                        <td>
                          {row.avg_durability
                            ? (
                                Math.round(row.avg_durability * 100) / 100
                              ).toFixed(2)
                            : "-"}
                          &#37;
                        </td>
                        <td>
                          {row.avg_moisture
                            ? (
                                Math.round(row.avg_moisture * 100) / 100
                              ).toFixed(2)
                            : "-"}
                          &#37;
                        </td>
                        <td>
                          {row.avg_moisture_content_alt
                            ? (
                                Math.round(row.avg_moisture_content_alt * 100) /
                                100
                              ).toFixed(2)
                            : "-"}
                          &#37;
                        </td>
                      </tr>
                    );
                  else return null;
                })}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <strong>Averages:</strong>
                </td>
                <td>
                  {(Math.round(getAverage(data, "avg_density") * 100) / 100)
                    .toFixed(2)
                    .toString()}
                </td>
                <td>
                  {(
                    Math.round(getAverage(data, "avg_fine_fraction") * 100) /
                    100
                  )
                    .toFixed(2)
                    .toString()}
                  &#37;
                </td>
                <td>
                  {(Math.round(getAverage(data, "avg_durability") * 100) / 100)
                    .toFixed(2)
                    .toString()}
                  &#37;
                </td>
                <td>
                  {(Math.round(getAverage(data, "avg_moisture") * 100) / 100)
                    .toFixed(2)
                    .toString()}
                  &#37;
                </td>
                <td>
                  {(
                    Math.round(
                      getAverage(data, "avg_moisture_content_alt") * 100
                    ) / 100
                  )
                    .toFixed(2)
                    .toString()}
                  &#37;
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col xs={12} lg={6} style={{ textAlign: "center" }}>
          <h4>Average Bulk Density</h4>

          <ResponsiveContainer
            width={"100%"}
            height={"80%"}
            minHeight={200}
            maxHeight={400}
          >
            <LineChart
              data={data.sort(
                (a, b) => Date.parse(a.date) - Date.parse(b.date)
              )}
              className="mb-4"
              margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
            >
              <ReferenceArea
                y1={750}
                y2={800}
                fillOpacity={0.5}
                fill="red"
                ifOverflow={"extendDomain"}
              />
              <ReferenceArea
                y1={600}
                y2={450}
                fillOpacity={0.5}
                fill="red"
                ifOverflow={"extendDomain"}
              />
              <Label value={"Avg. Bulk Density"} position={"outside"} />
              <Line type="monotone" dataKey="avg_density" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="date" tickFormatter={formatDate} />
              <YAxis scale={"linear"} domain={[450, 750]} />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </Col>
        <Col xs={12} lg={6} style={{ textAlign: "center" }}>
          <h4>Average Fine Fraction</h4>

          <ResponsiveContainer
            width={"100%"}
            height={"80%"}
            minHeight={200}
            maxHeight={400}
          >
            <LineChart
              data={data.sort(
                (a, b) => Date.parse(a.date) - Date.parse(b.date)
              )}
              className="mb-4"
              margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
            >
              <Line
                type="monotone"
                dataKey="avg_fine_fraction"
                stroke="#8884d8"
              />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="date" tickFormatter={formatDate} />
              <YAxis type="number" scale={"linear"} domain={[0, 1.5]} />
              <ReferenceArea
                y1={1}
                y2={1.5}
                fillOpacity={0.5}
                fill="red"
                ifOverflow={"extendDomain"}
              />

              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </Col>
        <Col xs={12} lg={6} style={{ textAlign: "center" }}>
          <h4>Average Mechanical Durability</h4>

          <ResponsiveContainer
            width={"100%"}
            height={"80%"}
            minHeight={200}
            maxHeight={400}
          >
            <LineChart
              data={data.sort(
                (a, b) => Date.parse(a.date) - Date.parse(b.date)
              )}
              className="mb-4"
              margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
            >
              <Line type="monotone" dataKey="avg_durability" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="date" tickFormatter={formatDate} />
              <YAxis scale={"linear"} domain={[95, 100]} />
              <Tooltip />
              <ReferenceArea
                y1={98}
                y2={95}
                fillOpacity={0.5}
                fill="red"
                ifOverflow={"extendDomain"}
              />
            </LineChart>
          </ResponsiveContainer>
        </Col>
        <Col xs={12} lg={6} style={{ textAlign: "center" }}>
          <h4>Average Moisture Content</h4>

          <ResponsiveContainer
            width={"100%"}
            height={"80%"}
            minHeight={200}
            maxHeight={400}
          >
            <LineChart
              data={data.sort(
                (a, b) => Date.parse(a.date) - Date.parse(b.date)
              )}
              className="mb-4"
              margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
            >
              <Line type="monotone" dataKey="avg_moisture" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="date" tickFormatter={formatDate} />
              <YAxis scale={"linear"} domain={[0, 15]} />
              <Tooltip />
              <ReferenceArea
                y1={10}
                y2={15}
                fillOpacity={0.5}
                fill="red"
                ifOverflow={"extendDomain"}
              />
            </LineChart>
          </ResponsiveContainer>
        </Col>
        <Col xs={12} lg={6} style={{ textAlign: "center" }}>
          <h4>Average Moisture Content (Oven)</h4>

          <ResponsiveContainer
            width={"100%"}
            height={"80%"}
            minHeight={200}
            maxHeight={400}
          >
            <LineChart
              data={data.sort(
                (a, b) => Date.parse(a.date) - Date.parse(b.date)
              )}
              className="mb-4"
              margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
            >
              <Line
                type="monotone"
                dataKey="avg_moisture_content_alt"
                stroke="#8884d8"
              />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="date" tickFormatter={formatDate} />
              <YAxis scale={"linear"} domain={[0, 15]} />
              <ReferenceArea
                y1={10}
                y2={15}
                fillOpacity={0.5}
                fill="red"
                ifOverflow={"extendDomain"}
              />

              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
}
function getAverage(data, key) {
  if (data.length === 0) return "-";
  else {
    let count = 0;
    let total = 0;
    data.forEach((value) => {
      if (key in value) {
        ++count;
        total = total + value[key];
      }
    });

    if (count === 0) ++count;
    return total / count;
  }
}
