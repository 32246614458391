import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router";
import MainNav from "../c_components/navbar_main";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";

// export default class SecureLayout extends Component {
//   static contextType = MsalContext;

//   render() {
//     const accountName = this.context.accounts[0].name;
//     return (
//       <Container>
//         <AuthenticatedTemplate>
//           <MainNav username={accountName.split(' ')[0]}/>
//           <Outlet />
//         </AuthenticatedTemplate>
//       </Container>
//     );
//   }
// }

export default function SecureLayout() {
  const { accounts } = useMsal();
  const accountName = accounts[0]?.name ?? "";
  return (
    <Container>
      <AuthenticatedTemplate>
        <MainNav username={accountName.split(" ")[0]} />
        <Outlet />
        <Row as="footer" style={{ margin: "10% 0 5% 0" }}>
          <Col style={{ textAlign: "center" }}>
            <hr />
            &copy; D3K Digital Ltd 2023
          </Col>
        </Row>
      </AuthenticatedTemplate>
    </Container>
  );
}
