//POST
///Returns an Integer or null on fail.
export async function CreatePelletTest(data) {
  let returnedId = await fetch(
    new URL(ModifyAPIPath("pellet-tests/add"), process.env.REACT_APP_PT_API),
    {
      method: "post",
      body: JSON.stringify(data),
    }
  )
    .then((e) => e.json())
    .then((data) => {
      const test_id = data.test_id;
      return test_id;
    })
    .catch((e) => {
      return null;
    });

  return returnedId;
}
//POST
export async function UpdatePelletTest(data) {
  console.log(data);
  let response = await fetch(
    new URL(ModifyAPIPath("pellet-tests/update"), process.env.REACT_APP_PT_API),
    { method: "POST", body: JSON.stringify(data) }
  )
    .then((res) => {
      return res.status;
    })
    .catch((e) => {
      return e.message;
    });

  return response;
}
//GET
export async function CheckIsLive() {
  return fetch(
    new URL(ModifyAPIPath("CheckIsLive"), process.env.REACT_APP_PT_API)
  );
}

export async function ArchivePelletTest(id) {
  const APIURL = new URL(
    ModifyAPIPath("archive_pellet_test"),
    process.env.REACT_APP_PT_API
  );
  APIURL.searchParams.set("id", id);
  return fetch(APIURL);
}

export async function GetPelletTests(request) {
  const url = new URL(request.url);
  const searchParams = url.searchParams;

  const APIURL = new URL(
    ModifyAPIPath("pellet-tests/get-full"),
    process.env.REACT_APP_PT_API
  );
  if (searchParams.get("fromDate") !== null)
    APIURL.searchParams.set("fromDate", searchParams.get("fromDate"));
  if (searchParams.get("toDate") !== null)
    APIURL.searchParams.set("toDate", searchParams.get("toDate"));
  APIURL.searchParams.set("search", searchParams.get("search") ?? "");
  return fetch(APIURL);
}

export async function GetDailyAverages(request) {
  const url = new URL(request.url);
  const toDate = url.searchParams.get("toDate");
  const fromDate = url.searchParams.get("fromDate");

  let returnValue = null;
  try {
    let toDefinitelyDate = FormatDate(toDate ?? Date.now());
    let fromDefinitelyDate = FormatDate(
      fromDate ?? Date.now() - 7 * 24 * 3600 * 1000
    );
    console.log(
      `Got Dates ${toDefinitelyDate} & ${fromDefinitelyDate}, from ${toDate} & ${fromDate}`
    );

    returnValue = await fetch(
      new URL(
        ModifyAPIPath(
          `views/dailyavgs?to=${toDefinitelyDate}&from=${fromDefinitelyDate}`
        ),
        process.env.REACT_APP_PT_API
      )
    );
  } catch (e) {
    returnValue = { error: "An Error Occured", message: e.message };
  }

  return returnValue;
}

export async function GetTestById(id) {
  let testID = parseInt(id);
  return fetch(
    new URL(
      ModifyAPIPath(`pellet-tests/get/${testID}`),
      process.env.REACT_APP_PT_API
    )
  );
}

//Helper
export function FormatDate(date) {
  if (date === undefined || date === null) return undefined;
  let d = new Date(date);
  return d.toISOString().substring(0, 10);
}

function IsDev() {
  return process.env.REACT_APP_DEV;
}
function ModifyAPIPath(path) {
  if (IsDev()) return path;
  else return "api/".concat(path);
}
